import { Module } from 'vuex';

import { TourState, RootState } from '@/types/store';

export const tourModule: Module<TourState, RootState> = {
    namespaced: true,
    state: {
        currentFloor: 0,
        currentRoom: 0,
    },
    mutations: {
        setFloor(state, floor: number | string) {
            state.currentFloor = floor;
        },
        setRoom(state, room: number | string) {
            state.currentFloor = room;
        },
    },
    getters: {
        getCurrentFloor: (state) => {
            return state.currentFloor;
        },
        getCurrentRoom: (state) => {
            return state.currentRoom;
        },
    },
    actions: {
        reset: ({ state }) => {
            state.currentFloor = 0;
            state.currentRoom = 0;
        },
    },
};
