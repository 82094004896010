import { createStore, Store, useStore as baseUseStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { InjectionKey } from 'vue';

import { RootState } from '@/types/store';

import { onboardModule } from './modules/onboard';
import { tourModule } from './modules/tour';
import { quizModule } from './modules/quiz';
import { navModule } from './modules/nav';

export const key: InjectionKey<Store<RootState>> = Symbol();

export const store = createStore<RootState>({
    state: {
        nav: {
            showBurger: false,
            homePath: '',
            visible: true,
            opaque: false,
        },
    },
    actions: {
        reset: ({ dispatch }) => {
            dispatch('onboard/reset');
            dispatch('quiz/reset');
            dispatch('tour/reset');
            dispatch('nav/reset');
        },
    },
    modules: {
        onboard: onboardModule,
        tour: tourModule,
        nav: navModule,
        quiz: quizModule,
    },
    plugins: [
        createPersistedState({
            paths: ['onboard', 'tour', 'quiz'],
        }),
    ],
});

export function useStore(): Store<RootState> {
    return baseUseStore(key);
}
