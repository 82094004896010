import { Module } from 'vuex';

import { QuizState, RootState } from '@/types/store';

export const quizModule: Module<QuizState, RootState> = {
    namespaced: true,
    state: {
        completedQuestions: [],
        currentQuestion: -1,
        correctAnswers: 0,
        totalQuestions: 0,
        hasAddedScore: false,
        totalPoints: 0,
        scoreID: -1,
    },
    mutations: {
        updatePoints: (state, points: number) => {
            state.totalPoints += points;
        },
        addCorrectAnswer: (state) => {
            state.correctAnswers += 1;
        },
        setTotalQuestions: (state, count: number) => {
            state.totalQuestions = count;
        },
        setHasAddedScore: (state, added: boolean) => {
            state.hasAddedScore = added;
        },
        setScoreID: (state, id: number) => {
            state.scoreID = id;
        },
    },
    actions: {
        updateScore: ({ commit }, points: number) => {
            commit('updatePoints', points);
            commit('addCorrectAnswer');
        },
        initScore: ({ commit }, id: number) => {
            commit('setHasAddedScore', true);
            commit('setScoreID', id);
        },
        reset: ({ state }) => {
            state.completedQuestions = [];
            state.currentQuestion = -1;
            state.correctAnswers = 0;
            state.totalQuestions = 0;
            state.hasAddedScore = !1;
            state.totalPoints = 0;
            state.scoreID = -1;
        },
    },
    getters: {
        getPoints: (state): number => {
            return state.totalPoints;
        },
        getCorrectAnswers: (state): number => {
            return state.correctAnswers;
        },
        getHasAddedScore: (state): boolean => {
            return state.hasAddedScore;
        },
        getScoreID: (state): number => {
            return state.scoreID;
        },
    },
};
