import { createApp } from 'vue';
import app from './app.vue';
import router from './router';
import { store, key } from './store';

// Custom fonts
import './fonts.css';

// Tailwind
import './tailwind.scss';

createApp(app).use(store, key).use(router).mount('#app');
