<template>
    <div
        class="nav-top fixed top-0 left-0 z-40 w-full flex items-center justify-between p-6"
        :class="{ hidden: !visible, 'bg-dark': opaque }"
    >
        <router-link to="/">
            <span class="logo font-serif text-xl leading-6 text-bright">Schloss<br />Waldburg</span>
        </router-link>
        <menu-icon class="w-8 h-8 text-bright" :class="{ hidden: !showBurger }" @click="toggleSwipeout"></menu-icon>
    </div>
    <div
        class="nav-swipeout fixed top-0 right-0 z-50 w-screen h-screen transition duration-300 translate-x-full bg-dark"
        :class="{ 'translate-x-0': open }"
    >
        <div class="transition-opacity delay-150 duration-300 opacity-0" :class="{ 'opacity-100': open }">
            <div class="absolute w-full flex items-center justify-between p-6">
                <span class="font-serif text-xl text-bright">Menü</span>
                <x-icon class="w-8 h-8 text-bright" @click="toggleSwipeout"></x-icon>
            </div>
            <div class="grid grid-cols-4 pt-half px-6">
                <div class="font-serif font-semibold text-4xl text-bright col-span-4 my-2" @click="closeAndGoto('/')">
                    Startseite
                </div>
                <div
                    class="font-serif font-semibold text-4xl text-bright col-span-4 my-2"
                    @click="closeAndGoto('/tour/floors')"
                >
                    Stockwerke
                </div>
                <div
                    class="font-serif font-semibold text-4xl text-bright col-span-4 my-2"
                    @click="closeAndGoto('/quiz/onboarding/age')"
                >
                    Quiz
                </div>
            </div>
            <div class="absolute left-6 bottom-6">
                <div class="font-serif text-xl text-bright mr-2" @click="closeAndGoto('/imprint')">Impressum</div>
                <div class="font-serif text-xl text-bright" @click="closeAndGoto('/privacy')">Datenschutz</div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onUnmounted, ref } from 'vue';
import { useStore } from '@/store';

import { MenuIcon, XIcon } from '@heroicons/vue/solid';
import { useRouter } from 'vue-router';

export default defineComponent({
    name: 'NavTop',
    components: {
        MenuIcon,
        XIcon,
    },
    setup() {
        const router = useRouter();
        const store = useStore();

        const showBurger = ref(false);
        const visible = ref(true);
        const opaque = ref(false);
        const open = ref(false);

        const unsubscribe = store.subscribe((mutation) => {
            if (mutation.type == 'nav/setTransparent') {
                opaque.value = false;
            } else if (mutation.type == 'nav/setOpaque') {
                opaque.value = true;
            } else if (mutation.type == 'nav/hide') {
                visible.value = false;
            } else if (mutation.type == 'nav/show') {
                visible.value = true;
            } else if (mutation.type == 'nav/hideBurger') {
                showBurger.value = false;
            } else if (mutation.type == 'nav/showBurger') {
                showBurger.value = true;
            }
        });

        const toggleSwipeout = () => {
            open.value = !open.value;
        };

        const closeAndGoto = (to: string) => {
            open.value = false;
            router.push(to);
        };

        onUnmounted(() => {
            unsubscribe();
        });

        return {
            toggleSwipeout,
            closeAndGoto,
            showBurger,
            visible,
            opaque,
            open,
        };
    },
});
</script>
