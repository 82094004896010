
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Welcome } from '@/types/welcome';

import { useMedia } from '@/compose/media';
import { useAPI } from '@/compose/api';
import { useStore } from '@/store';

import ActionButton from '@/components/buttons/action-button.vue';
import Wrapper from '@/components/layout/wrapper.vue';

export default defineComponent({
    name: 'Home',
    components: {
        ActionButton,
        Wrapper,
    },
    setup() {
        const { getImageUrl } = useMedia();
        const { getWelcome } = useAPI();
        const router = useRouter();
        const store = useStore();

        const loading = ref(true);
        const failed = ref(false);
        const data = ref({} as Welcome);

        const showVideo = () => {
            const d = data.value;
            router.push({ name: 'welcome', params: { id: d.video.id } });
        };

        const start = () => {
            router.push('/tour/floors');
        };

        onMounted(() => {
            loading.value = true;
            getWelcome()
                .then((res) => {
                    data.value = res.data;
                })
                .catch((err) => {
                    failed.value = true;
                })
                .finally(() => {
                    loading.value = false;
                });

            store.commit('nav/setHomePath', '/');
            store.commit('nav/setTransparent');
            store.commit('nav/hideBurger');
        });

        return {
            getImageUrl,
            showVideo,
            loading,
            failed,
            start,
            data,
        };
    },
});
