<template>
    <nav-top></nav-top>
    <router-view class="bg-dark" />
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import NavTop from '@/components/nav/top.vue';

export default defineComponent({
    components: {
        NavTop,
    },
});
</script>

<style lang="scss">
#app {
    overflow-y: auto;
}
</style>
