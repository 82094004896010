<template>
    <div class="wrapper p-6 grid grid-cols-4 place-content-start h-fill">
        <slot></slot>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'Wrapper',
});
</script>
