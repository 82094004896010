import axios from 'axios';
import { Score, UpdateScore } from '@/types/scores';
import { Welcome } from '@/types/welcome';

const instance = axios.create({
    baseURL: process.env.VUE_APP_CMS_API_URL,
});

// eslint-disable-next-line
export const useAPI = () => {
    const getWelcome = () => {
        return instance.get<Welcome>('/welcome');
    };

    const getOnboard = () => {
        return instance.get('/onboard');
    };

    const getMedia = (id: string) => {
        return instance.get(`/upload/files/${id}`);
    };

    const getOnboardQuestion = (id: string) => {
        return instance.get(`/onboard-questions/${id}`);
    };

    const getFloors = () => {
        return instance.get('/floors?_sort=floor_number:ASC');
    };

    const getFloor = (id: string) => {
        return instance.get(`/floors/${id}`);
    };

    const getRoom = (id: string) => {
        return instance.get(`/rooms/${id}`);
    };

    const getQuizStart = () => {
        return instance.get('/quiz-start');
    };

    const getQuiz = (age: string) => {
        return instance.get(`/quizzes?age=${encodeURIComponent(age)}`);
    };

    const getQuizEnd = () => {
        return instance.get('/quiz-end');
    };

    const getQuizQuestion = (id: string) => {
        return instance.get(`/questions/${id}`);
    };

    const addScore = (score: Score) => {
        return instance.post('/scores', score);
    };

    const updateScore = (update: UpdateScore) => {
        return instance.put(`/scores/${update.id}`, update.score);
    };

    const getImprint = () => {
        return instance.get('/imprint');
    };

    const getPrivacy = () => {
        return instance.get('/privacy');
    };

    return {
        getOnboardQuestion,
        getQuizQuestion,
        getQuizStart,
        updateScore,
        getQuizEnd,
        getWelcome,
        getOnboard,
        getImprint,
        getPrivacy,
        getFloors,
        getFloor,
        getMedia,
        addScore,
        getRoom,
        getQuiz,
    };
};
