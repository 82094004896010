
import { defineComponent } from 'vue';

import { ArrowNarrowRightIcon } from '@heroicons/vue/solid';

export default defineComponent({
    name: 'ActionButton',
    props: {
        text: {
            type: String,
            default: 'Weiter',
        },
    },
    components: {
        ArrowNarrowRightIcon,
    },
});
