import { Image } from '@/types/media';

export const useMedia = () => {
    const getImageUrl = (image: Image, format: string): string => {
        let url = '';

        switch (format) {
            case 'xlarge':
                if (image.formats.xlarge == undefined) {
                    url = image.url;
                } else {
                    url = image.formats.xlarge.url;
                }
                break;

            case 'large':
                if (image.formats.large == undefined) {
                    url = image.url;
                } else {
                    url = image.formats.large.url;
                }
                break;

            case 'medium':
                if (image.formats.medium == undefined) {
                    url = image.url;
                } else {
                    url = image.formats.medium.url;
                }
                break;

            case 'small':
                if (image.formats.small == undefined) {
                    url = image.url;
                } else {
                    url = image.formats.small.url;
                }
                break;

            case 'thumbnail':
                if (image.formats.thumbnail == undefined) {
                    url = image.url;
                } else {
                    url = image.formats.thumbnail.url;
                }
                break;

            default:
                url = image.url;
                break;
        }

        return `${process.env.VUE_APP_CMS_API_URL}${url}`;
    };

    const getVideoUrl = (url: string): string => {
        if (url.startsWith(process.env.VUE_APP_CMS_API_URL)) {
            return url;
        }

        return `${process.env.VUE_APP_CMS_API_URL}${url}`;
    };

    return {
        getImageUrl,
        getVideoUrl,
    };
};
