<template>
    <div class="home">
        <div v-if="!failed">
            <div class="image-wrapper absolute top-0 left-0 z-0">
                <img
                    class="h-screen w-screen object-cover"
                    v-if="data.background"
                    :src="getImageUrl(data.background, 'xlarge')"
                    :alt="data.background.alternativeText"
                />
                <div class="absolute w-full h-full inset-0 bg-dark bg-opacity-40"></div>
            </div>
            <wrapper class="place-content-start h-screen z-10 relative pt-half">
                <h1 class="text-4/5xl font-serif font-semibold text-bright col-span-4">
                    {{ data.title }}
                </h1>
                <p class="col-span-4 text-bright text-xl mt-6">{{ data.text }}</p>
                <action-button text="Video anschauen" class="col-span-3 mt-6" @click="showVideo"></action-button>
                <action-button text="Tour starten" class="col-span-3 mt-6" @click="start"></action-button>
            </wrapper>
        </div>
        <div v-else>
            <wrapper class="pt-24">
                <h1 class="text-4xl font-serif font-semibold text-bright col-span-4">
                    Anwendung konnte nicht geladen werden
                </h1>
            </wrapper>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { Welcome } from '@/types/welcome';

import { useMedia } from '@/compose/media';
import { useAPI } from '@/compose/api';
import { useStore } from '@/store';

import ActionButton from '@/components/buttons/action-button.vue';
import Wrapper from '@/components/layout/wrapper.vue';

export default defineComponent({
    name: 'Home',
    components: {
        ActionButton,
        Wrapper,
    },
    setup() {
        const { getImageUrl } = useMedia();
        const { getWelcome } = useAPI();
        const router = useRouter();
        const store = useStore();

        const loading = ref(true);
        const failed = ref(false);
        const data = ref({} as Welcome);

        const showVideo = () => {
            const d = data.value;
            router.push({ name: 'welcome', params: { id: d.video.id } });
        };

        const start = () => {
            router.push('/tour/floors');
        };

        onMounted(() => {
            loading.value = true;
            getWelcome()
                .then((res) => {
                    data.value = res.data;
                })
                .catch((err) => {
                    failed.value = true;
                })
                .finally(() => {
                    loading.value = false;
                });

            store.commit('nav/setHomePath', '/');
            store.commit('nav/setTransparent');
            store.commit('nav/hideBurger');
        });

        return {
            getImageUrl,
            showVideo,
            loading,
            failed,
            start,
            data,
        };
    },
});
</script>
