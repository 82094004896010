import { Module } from 'vuex';

import { OnboardState, RootState } from '@/types/store';

export const onboardModule: Module<OnboardState, RootState> = {
    namespaced: true,
    state: {
        groupname: '',
        mode: 'alone',
        name: '',
        age: '',
    },
    mutations: {
        setAge: (state: OnboardState, age: string) => {
            state.age = age;
        },
        setMode: (state: OnboardState, mode: string) => {
            state.mode = mode;
        },
        setName: (state: OnboardState, name: string) => {
            state.name = name;
        },
        setGroupName: (state: OnboardState, name: string) => {
            state.groupname = name;
        },
    },
    getters: {
        getAge: (state): string => {
            return state.age;
        },
        getMode: (state): string => {
            return state.mode;
        },
        getName: (state): string => {
            return state.name;
        },
        getGroupName: (state): string => {
            return state.groupname;
        },
    },
    actions: {
        reset: ({ state }) => {
            state.groupname = '';
            state.mode = 'alone';
            state.name = '';
            state.age = '';
        },
    },
};
