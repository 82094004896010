import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import Home from '../views/home.vue';

import { getPageTitle } from '@/utils';

const title = getPageTitle();

const withPrefix = (prefix: string, routes: Array<RouteRecordRaw>) =>
    routes.map((route) => {
        route.path = prefix + route.path;
        return route;
    });

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            protected: false,
            title: 'Willkommen auf dem Schloss Waldburg | ' + title,
        },
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: () => import(/* webpackChunkName: "imprint" */ '../views/imprint.vue'),
        meta: {
            protected: false,
            title: 'Impressum | ' + title,
        },
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy.vue'),
        meta: {
            protected: false,
            title: 'Datenschutz | ' + title,
        },
    },
    {
        path: '/end',
        name: 'end',
        component: () => import(/* webpackChunkName: "end" */ '../views/end.vue'),
        meta: {
            title: 'Auf Wiedersehen auf dem Schloss Waldburg | ' + title,
        },
    },
    {
        path: '/error',
        name: 'error',
        component: () => import(/* webpackChunkName: "error" */ '../views/error.vue'),
        meta: {
            title: 'Es ist ein Fehler aufgetreten | ' + title,
        },
    },
    {
        path: '/welcome/:id',
        name: 'welcome',
        component: () => import(/* webpackChunkName: "welcome-video" */ '../views/welcome/video.vue'),
        meta: {
            title: 'Persönliche Begrüßung auf dem Schloss Waldburg | ' + title,
        },
    },
    {
        path: '/tour/floors',
        component: () => import(/* webpackChunkName: "tour-floors" */ '../views/tour/floor-overview.vue'),
        meta: {
            title: 'Übersicht der Stockwerke auf dem Schloss | ' + title,
        },
    },
    {
        path: '/tour/floors/:id',
        name: 'floor',
        component: () => import(/* webpackChunkName: "tour-rooms" */ '../views/tour/room-overview.vue'),
    },
    {
        path: '/tour/rooms/:id',
        name: 'room',
        component: () => import(/* webpackChunkName: "tour-room" */ '../views/tour/room.vue'),
    },
    {
        path: '/tour/room-details/:id',
        name: 'room-details',
        component: () => import(/* webpackChunkName: "tour-room-details" */ '../views/tour/room-details.vue'),
    },
    ...withPrefix('/quiz', [
        {
            path: '/start',
            name: 'quiz-start',
            component: () => import(/* webpackChunkName: "quiz-start" */ '../views/quiz/start.vue'),
            meta: {
                title: 'Quiz starten | ' + title,
            },
        },
        {
            path: '/end',
            name: 'quiz-end',
            component: () => import(/* webpackChunkName: "quiz-end" */ '../views/quiz/end.vue'),
            meta: {
                title: 'Quiz beendet | ' + title,
            },
        },
        {
            path: '/questions/:id',
            name: 'quiz-question',
            component: () => import(/* webpackChunkName: "quiz-question" */ '../views/quiz/question.vue'),
            meta: {
                title: 'Quiz Frage | ' + title,
            },
        },
        ...withPrefix('/onboarding', [
            {
                path: '/age',
                name: 'quiz-onboarding-age',
                component: () =>
                    import(/* webpackChunkName: "quiz-onboarding-age" */ '../views/quiz/onboarding/age.vue'),
                meta: {
                    title: 'Dein Alter | ' + title,
                },
            },
            {
                path: '/mode',
                name: 'quiz-onboarding-mode',
                component: () =>
                    import(/* webpackChunkName: "quiz-onboarding-mode" */ '../views/quiz/onboarding/mode.vue'),
                meta: {
                    title: 'Spielst du alleine oder in einer Gruppe? | ' + title,
                },
            },
            {
                path: '/name',
                name: 'quiz-onboarding-name',
                component: () =>
                    import(/* webpackChunkName: "quiz-onboarding-name" */ '../views/quiz/onboarding/name.vue'),
                meta: {
                    title: 'Dein Name | ' + title,
                },
            },
            {
                path: '/group',
                name: 'quiz-onboarding-group',
                component: () =>
                    import(/* webpackChunkName: "quiz-onboarding-group" */ '../views/quiz/onboarding/group.vue'),
                meta: {
                    title: 'Eine neue Gruppe erstellen oder einer anderen beitreten | ' + title,
                },
            },
        ]),
    ]),
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to) => {
    document.title = to.meta.title != undefined ? (to.meta.title as string) : title;
});

export default router;
