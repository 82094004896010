<template>
    <div role="button" class="action-button">
        <span class="font-serif font-semibold text-lg">{{ text }}</span>
        <arrow-narrow-right-icon class="w-4 h-4"></arrow-narrow-right-icon>
    </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { ArrowNarrowRightIcon } from '@heroicons/vue/solid';

export default defineComponent({
    name: 'ActionButton',
    props: {
        text: {
            type: String,
            default: 'Weiter',
        },
    },
    components: {
        ArrowNarrowRightIcon,
    },
});
</script>
