import { Module } from 'vuex';

import { NavState, RootState } from '@/types/store';

export const navModule: Module<NavState, RootState> = {
    namespaced: true,
    state: {
        showBurger: false,
        visible: true,
        opaque: false,
        homePath: '/',
    },
    mutations: {
        show(state) {
            state.visible = true;
        },
        hide(state) {
            state.visible = false;
        },
        setOpaque(state) {
            state.opaque = true;
        },
        setTransparent(state) {
            state.opaque = false;
        },
        setHomePath(state, path: string) {
            state.homePath = path;
        },
        showBurger(state) {
            state.showBurger = true;
        },
        hideBurger(state) {
            state.showBurger = false;
        },
    },
    getters: {
        isVisible: (state) => () => {
            return state.visible;
        },
        isOpaque: (state) => {
            return state.opaque;
        },
        getHomePath: (state) => {
            return state.homePath;
        },
    },
    actions: {
        reset: ({ state }) => {
            state.visible = true;
            state.opaque = false;
            state.homePath = '/';
        },
    },
};
