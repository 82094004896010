
import { defineComponent } from 'vue';

import NavTop from '@/components/nav/top.vue';

export default defineComponent({
    components: {
        NavTop,
    },
});
